import { useEffect, useState, useCallback } from 'react';
import './App.css';
import * as constants from './constants';

function App() {
  // Input
  const [devs, setDevs] = useState(1);
  const [experience, setExperience] = useState('mid');

  // Format
  const dollarUSLocale = Intl.NumberFormat('en-US');

  // Calculations
  const [simbiosisSavings, setSimbiosisSavings] = useState(0);
  
  const calculateCost = useCallback((hourlyCost) => {    
    return (hourlyCost[experience] * constants.monthlyHours * constants.calculatedMonths) * devs;
  }, [devs, experience]);

  useEffect (() => {    
    setSimbiosisSavings(calculateCost(constants.usaHourlyCosts) - calculateCost(constants.simbiosisHourlyCosts));
  }, [calculateCost]);  

  // Render
  return(
    <div className="App">
      <div className="bg-transparent py-12 px-6 sm:px-12 w-full flex flex-col justify-center items-center gap-10">

        {/* HEADER */}        
        <p className="title text-5xl font-medium text-gray-100 text-center">
          {constants.calculatorTitle}
        </p>
        <p className="text-lg font-normal text-gray-100 text-center">
          {constants.calculatorSubtitle}
        </p>

        {/* INPUTS */}
        <div className="flex px-6 sm:px-0 gap-10 items-center justify-center flex-wrap sm:flex-nowrap">
          <div className="flex flex-col">
            <div className="bg-gray-100 text-gray-800 w-64 font-bold flex rounded-lg p-4 justify-between items-center gap-2 h-14">
              <p className="text-sm">
                Developers:
              </p>
              <input className="bg-transparent w-full outline-none text-gray-800 font-bold"
                type="number" min="0" value={devs}
                onChange={e => setDevs(e.target.value)}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className="bg-gray-100 text-gray-800 w-64 font-bold flex rounded-lg p-4 justify-between items-center gap-2 h-14">
              <p className="text-sm">
                Experience:
              </p>
              <select className="bg-transparent w-full text-gray-800 font-bold"
                value={experience}
                onChange={e => setExperience(e.target.value)}>
                {/* <option disabled value="jr">Junior</option> */}
                <option value="mid">{constants.midLabel}</option>
                <option value="sr">{constants.srLabel}</option>
              </select>
            </div>
          </div>
        </div>

        {/* CALCULATIONS */}
        <div className="flex flex-col w-full justify-center gap-10 md:hidden">
          <div className="border-t-2 separator-color" />
          <div className="flex flex-col gap-5 sm:items-center">
            <p className="text-gray-100 text-3xl font-medium">{constants.usaLabel}</p>
            <p className="text-3xl font-medium text-red-400">
              ${dollarUSLocale.format(calculateCost(constants.usaHourlyCosts))} {constants.expensesLabel}
            </p>
          </div>
          <div className="border-t-2 separator-color" />
          <div className="flex flex-col gap-5 sm:items-center">
            <p className="text-gray-100 text-3xl font-medium">{constants.latamLabel}</p>
            <p className="text-3xl font-medium text-red-400">
              ${dollarUSLocale.format(calculateCost(constants.latamHourlyCosts))} {constants.expensesLabel}
            </p>
          </div>
          <div className="border-t-2 separator-color" />
          <div className="flex flex-col gap-5 sm:items-center">
            <img
              alt="Sibmiosis"
              src={constants.logoUrl}
              width="200" className=""
            />
            <p className="text-3xl font-medium text-green-400">
              ${dollarUSLocale.format(simbiosisSavings)} {constants.savingsLabel}
            </p>
            <p className="text-xl font-normal text-gray-100">
              ${dollarUSLocale.format(calculateCost(constants.simbiosisHourlyCosts))} {constants.calculationLabel}
            </p>
            <a href={constants.simbiosisActionUrl} target="_blank" className="mt-1" rel="noreferrer">
              <button className="bg-blue-500 rounded-2xl px-8 py-4 text-lg text-white text-lg font-bold" type="button">
                {constants.simbiosisAction}
              </button>
            </a>
          </div>
        </div>
        <div className="hidden md:flex w-full justify-center gap-16 pt-10 border-t-2 separator-color">
          <div className="flex flex-col gap-5">
            <p className="text-gray-100 text-3xl font-medium">{constants.usaLabel}</p>
            <div>
              <p className="text-3xl font-medium text-red-400">
                ${dollarUSLocale.format(calculateCost(constants.usaHourlyCosts))} {constants.expensesLabel}
              </p>
            </div>
            <p className="text-gray-100 text-3xl font-medium mt-10">{constants.latamLabel}</p>
            <div>
              <p className="text-3xl font-medium text-red-400">
                ${dollarUSLocale.format(calculateCost(constants.latamHourlyCosts))} {constants.expensesLabel}
              </p>
            </div>
          </div>
          <div className="border-r-2 separator-color" />
          <div className="flex flex-col gap-5">
            <img
              alt="Sibmiosis"
              src={constants.logoUrl}
              width="200"
            />
            <div>
              <p className="text-3xl font-medium text-green-400">
                ${dollarUSLocale.format(simbiosisSavings)} {constants.savingsLabel}
              </p>              
            </div>
            <p className="text-xl font-normal text-gray-100">
              ${dollarUSLocale.format(calculateCost(constants.simbiosisHourlyCosts))} {constants.calculationLabel}
            </p>
            <p className="text-lg text-gray-100">{constants.simbiosisLabel}</p>
            <a href={constants.simbiosisActionUrl} target="_blank" rel="noreferrer">
              <button className="bg-blue-500 rounded-2xl px-8 py-4 text-lg text-white text-lg font-bold" type="button">
                {constants.simbiosisAction}
              </button>
            </a>
          </div>
        </div>

      </div>      
    </div>
  );
}

export default App;
