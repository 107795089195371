// App
export const logoUrl = 'https://uploads-ssl.webflow.com/5f59597dba2ac7d5f173117e/6213f8831b3f79d0c66e4fac_white-Logo.svg';

// Hourly costs
export const simbiosisHourlyCosts ={
    jr: 0,
    mid: 25,
    sr: 40,
};
export const latamHourlyCosts ={
    jr: 0,
    mid: 40,
    sr: 60,
};
export const usaHourlyCosts ={
    jr: 0,
    mid: 56,
    sr: 100,
};

// Calculations
export const monthlyHours = 160;
export const calculatedMonths = 12;

// Labels
export const calculatorTitle = 'Calculate your savings';
export const calculatorSubtitle = 'Compare the cost of hiring in relation to the average cost in other software agencies.';

export const midLabel = 'Mid-Level';
export const srLabel = 'Senior';

export const usaLabel = 'US Based';
export const latamLabel = 'Nearshore Agencies';

export const simbiosisLabel = 'Grow your tech team today!';
export const simbiosisAction = 'Start the process'; 
export const simbiosisActionUrl = 'https://simbiosisteam.typeform.com/start#referrer=website'; 

export const calculationLabel = 'annually';
export const expensesLabel = 'spent per year';
export const savingsLabel = 'saved per year';
